<template>
  <div class="element-row row">
    <div class="index col-4 col-md-3">[<slot name="index"></slot>]</div>
    <div class="content col-8 col-md-9">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.element-row {
  margin-bottom: 10rem;

  .index {
    color: #a6a3a3;
    font-size: 0.75rem;
    white-space: nowrap;

    @media screen and (min-width: 600px) {
      font-size: 1rem;
    }

    @media screen and (min-width: 768px) {
      font-size: 1.125rem;
    }
  }

  .content {
    font-size: 0.625rem;

    @media screen and (min-width: 600px) {
      font-size: 1rem;
    }

    @media screen and (min-width: 768px) {
      font-size: 1.125rem;
    }
  }
}
</style>
