<template>
  <div class="launching-page">
    <the-header @open-modal="openModal"></the-header>
    <div class="content">
      <indexed-content>
        <template v-slot:index> release </template>
        <div class="spaced">
          <span>- / 11 / 2023</span>
          <span>[ november ]</span>
        </div>
      </indexed-content>

      <indexed-content>
        <template v-slot:index> about </template>
        <span>
          imageboard - conceptboard - archive - research - cataloguing - feed -
          id
        </span>
      </indexed-content>

      <indexed-content>
        <template v-slot:index> tag </template>
        <span>
          ATER - from Lat. [ ater - atra - atrum : black , groomy , sombre ]
          <br />
          <br />
          ATER - from Rm. <span class="underlined">A</span>ziende
          <span class="underlined">T</span>erritoriali per l’<span
            class="underlined"
            >E</span
          >dilizia <span class="underlined">R</span>esidenziale [ 00171 ]
        </span>
      </indexed-content>

      <indexed-content>
        <template v-slot:index> mark </template>
        <span>
          ATER sm / theoretical research
          <br />
          <br />
          ATER tm / product process
        </span>
      </indexed-content>
    </div>
    <the-footer></the-footer>

    <newsletter-form v-if="showModal" @close="closeModal" />
  </div>
</template>

<script>
import TheHeader from "@/components/LaunchingPage/TheHeader.vue";
import TheFooter from "@/components/LaunchingPage/TheFooter.vue";
import NewsletterForm from "@/components/LaunchingPage/NewsletterForm.vue";

export default {
  components: {
    TheHeader,
    TheFooter,
    NewsletterForm,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped lang="scss">
.launching-page {
  color: white;
  width: 100%;
  height: 100%;
}
</style>
