<template>
  <div class="ater-container">
    <router-view />
  </div>
</template>

<script>
import "@/assets/styles/css/style.css";
export default {};
</script>

<style lang="scss">
body {
  background-color: black;
  font-weight: 600;
}
</style>
