<template>
  <div class="header">
    <div class="main-description">LAUNCHING PAGE</div>
    <div
      v-if="isDesk"
      class="sub-description sub-description__desktop"
      @click="openModal"
    >
      ( full site soon - <span class="underlined">add email</span> to be
      notified for the drop )
    </div>
    <div
      v-else
      class="sub-description sub-description__mobile underlined"
      @click="openModal"
    >
      add email for release
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    openModal() {
      this.$emit("open-modal");
    },
  },
  computed: {
    isDesk: function () {
      return this.windowWidth > 768;
    },
  },
  watch: {
    isDesk() {},
  },
};
</script>

<style scoped lang="scss">
.header {
  height: 100px;
  margin-bottom: 3.25rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
}

.main-description {
  font-size: 1.15rem;

  @media screen and (min-width: 768px) {
    font-size: 1.35rem;
  }
}

.sub-description {
  font-style: italic;
  cursor: pointer;

  &__mobile {
    font-size: 0.625rem;
  }

  @media screen and (min-width: 768px) and (max-width: 860px) {
    font-size: 0.8rem;
  }

  @media screen and (min-width: 860px) {
    font-size: 1rem;
  }
}
</style>
