<template>
  <div class="footer">
    <span class="main-text">by <ater-logo :is-xs="true"></ater-logo></span>
    <span class="sub-text">100celle - 2023</span>
  </div>
</template>

<script>
import AterLogo from "../AterLogo.vue";

export default {
  components: {
    AterLogo,
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 100px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;

  @media screen and (min-width: 768px) {
    justify-content: space-between;
  }

  .main-text {
    font-size: 0.625rem;

    @media screen and (min-width: 768px) {
      font-size: 0.875rem;
    }
  }

  .sub-text {
    display: none;

    @media screen and (min-width: 768px) {
      display: inline-block;
      font-size: 1.125rem;
    }
  }
}
</style>
