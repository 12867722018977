<template>
  <div class="newsletter-form">
    <div class="newsletter-form__overlay" @click="closeModal"></div>
    <div class="newsletter-form__content">
      <div class="newsletter-form__header">
        <span class="underlined">newsletter</span> - release online site
        <span class="close-button" @click="closeModal">
          <img src="../../assets/img/close-button.svg" alt="" />
        </span>
      </div>
      <div v-if="!isSubmitted">
        <div class="newsletter-form__body">
          <indexed-content>
            <template v-slot:index> email </template>
            <div class="form-container">
              <span>insert here:</span>
              <form @submit.prevent="submitForm" id="form">
                <input
                  type="email"
                  id="email"
                  name="email"
                  v-model="enteredEmail"
                  required
                />
              </form>
            </div>
          </indexed-content>
        </div>

        <div class="newsletter-form__footer">
          <span>when the site is online you will receive a notification.</span>
        </div>
      </div>
      <div class="thanks" v-else>
        <span>- thank u -</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      enteredEmail: "",
      isSubmitted: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    submitForm() {
      const content = new FormData();
      content.append("email", this.enteredEmail);
      fetch(
        "https://script.google.com/macros/s/AKfycbx3ksQ1KJct_EoL2HwE_SFiB9QXYxYcI3VRgR6pYBKrcPCmfOB3imp5Ng67UkTeJmsr/exec",
        {
          method: "POST",
          body: content,
        }
      ).catch((err) => {
        console.log(err);
      });
      this.isSubmitted = true;
      setTimeout(() => {
        this.$emit("close");
      }, 2500);
    },
  },
};
</script>

<style lang="scss" scoped>
.newsletter-form {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }

  &__content {
    background-color: #d9d9d9;
    z-index: 20;
    width: 45%;
    max-width: 715px;
    border-radius: 0 0 25px 25px;

    @media screen and (max-width: 1024px) {
      width: 65%;
    }

    @media screen and (max-width: 768px) {
      width: 75%;
    }

    @media screen and (max-width: 480px) {
      width: 90%;
    }
  }

  &__header {
    position: relative;
    background-color: white;
    color: black;
    text-align: center;
    padding: 5px 40px 5px 10px;
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 7rem;

    @media screen and (max-width: 680px) {
      font-size: 0.9rem;
    }

    @media screen and (max-width: 375px) {
      padding-right: 30px;
    }

    @media screen and (max-width: 330px) {
      font-size: 0.8rem;
    }

    span {
      font-style: italic;
      font-size: 0.95rem;
      @media screen and (max-width: 680px) {
        font-size: 0.75rem;
      }

      @media screen and (max-width: 330px) {
        font-size: 0.6rem;
      }
    }
  }

  &__body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #d9d9d9;
    margin-bottom: 7rem;
    padding-left: 5vw;

    .form-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      color: black;
      span {
        font-size: 0.5rem;
        font-weight: 700;
      }
    }

    form {
      width: 80%;
      text-align: center;
      input {
        outline: 0;
        border: 0;
        border-radius: 0;
        -webkit-appearance: none;
        border-bottom: 1px dashed black;
        background-color: transparent;
        width: 100%;
      }
    }
    .element-row {
      width: 100%;
      max-width: 500px;
      margin-bottom: 0;
      font-size: 0.875rem;
      align-items: flex-end;
    }
  }

  &__footer {
    font-size: 0.5rem;
    font-weight: 700;
    color: black;
    text-align: center;
    margin-bottom: 2.5rem;
  }

  .thanks {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    margin-bottom: 7rem;
    font-weight: 400;
    font-size: 14px;
  }
}

.close-button {
  position: absolute;
  right: 15px;
  cursor: pointer;

  @media screen and (max-width: 375px) {
    top: 6px;
    right: 10px;
    img {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
