import { createRouter, createWebHistory } from "vue-router";
import LaunchingPage from "../views/LaunchingPage.vue";

const routes = [
  { path: "/", name: "launching-page", component: LaunchingPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
