<template>
  <span :class="[{ 'ater-logo__xs': isXs }, 'ater-logo']">
    [ ATER <sup>sm</sup> ]
  </span>
</template>

<script>
export default {
  props: ["isXs"],
};
</script>

<style lang="scss" scoped>
.ater-logo {
  position: relative;
  font-size: 2.5rem;
  padding-bottom: 0.5rem;
  sup {
    position: absolute;
    right: 1.5rem;
    top: 1rem;
    font-size: 1rem;
  }

  &__xs {
    font-size: 1rem;
    font-weight: 600;

    @media screen and (min-width: 768px) {
      font-size: 1.25rem;
    }

    sup {
      font-size: 0.4rem;
      top: 0.4rem;
      right: 0.65rem;

      @media screen and (min-width: 768px) {
        font-size: 0.5rem;
        top: 0.5rem;
        right: 0.8rem;
      }
    }
  }
}
</style>
